import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import MediaQuery from "components/MediaQuery";
import SkillPath from "components/common/Skills/SkillPath";
import { ChampionSkill } from "@outplayed/tooltips";
import ChampionSkillWithLabel from "components/common/Skills/ChampionSkillWithLabel";

import { getPercetange, numberWithCommas } from "lib/general-helper";
import { getTierColor } from "lib/tierHelper";
import { ReactComponent as ArrowRight } from "svg/arrow-right.svg";

const Skills = (props) => {
  const { t } = useTranslation();
  const { className, championId, skillPriority, skillPath, evolutionPath, championName } = props;

  const classNames = classnames("content-section", "content-section_no-padding", "recommended-build_skills", className);
  const skillWinRate = skillPath && getPercetange(skillPath.wins, skillPath.matches, 2);
  const winRateColor = getTierColor(skillWinRate);

  const skillEvolution = evolutionPath && evolutionPath.items && evolutionPath.items.length > 0 && (
    <div className="evolution-path">
      <div className="evolution-label">Evolution Order:</div>
      {evolutionPath.items.map((skill, index) => (
        <React.Fragment key={index}>
          <ChampionSkillWithLabel
            className="evolution-skill"
            label={skill}
            labelPosition="bottomRight"
            championId={championId}
            skillSlot={skill}
            ssr
          />
          {index !== evolutionPath.items.length - 1 && <ArrowRight className="arrow-right" />}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className={classNames}>
      <div className="content-section_content skill-priority">
        <div className="content-section_header">{t("Skill Priority")}</div>
        <div className="skill-priority_content">
          <div className="skill-priority-path">
            {skillPriority.items.map((skill, index) => {
              return (
                <React.Fragment key={index}>
                  <ChampionSkillWithLabel
                    label={skill}
                    labelPosition="bottomCenter"
                    championId={championId}
                    skillSlot={skill}
                    ssr
                  />
                  {index !== 2 && <ArrowRight className="arrow-right" />}
                </React.Fragment>
              );
            })}
          </div>
          {skillWinRate && (
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <div className={`winrate ${winRateColor}`}>
                <span>{`${skillWinRate}% `}</span>WR
              </div>
              <div className="matches">{`${numberWithCommas(skillPath.matches)} Matches`}</div>
            </div>
          )}
        </div>
      </div>
      <div className="content-section_content skill-path-block">
        <div className="content-section_header">
          <div className="main-header">
            <span style={{ marginRight: 12 }}>{t("Skill Path")}</span>
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              <span className="content-section_blurb">{t("Most popular ability leveling order")}</span>
            </MediaQuery>
          </div>
          {skillEvolution && (
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              {skillEvolution}
            </MediaQuery>
          )}
        </div>
        {skillEvolution && (
          <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
            <div className="mobile-evolution-path">{skillEvolution}</div>
          </MediaQuery>
        )}
        <SkillPath championId={championId} skillOrder={(skillPath && skillPath.items) || []} skillNames includePassive rec ssr />
      </div>
    </div>
  );
};

export default Skills;
