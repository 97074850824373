import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import MediaQuery from "components/MediaQuery";
import { Item } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { stackDuplicateItems } from "components/Champions/championsHelper";
import { getPercetange, numberWithCommas } from "lib/general-helper.js";
import { ReactComponent as ArrowRight } from "svg/arrow-right.svg";

const MythicItem = (props) => {
  const { itemId, ...itemProps } = props;
  const { useRiotItems, isMythicItem } = getRiotAssetsContext();
  const { data } = useRiotItems({ itemId, ...itemProps });

  return props.children(isMythicItem((data || {})[itemId]));
};

const CoreItemsPath = (props) => {
  const { t } = useTranslation();
  const { coreItems, mythicItems, itemProps } = props;
  const { spriteSize, mythicSpriteSize } = itemProps;
  // const coreHasMythic = mythicItems && coreItems && coreItems.items &&
  //   coreItems.items.findIndex(item => mythicItems.includes(item));
  const coreHasMythic = -1;
  return (
    <React.Fragment>
      <div className={classnames("item-row", { "mythic-row": coreHasMythic === 0 })}>
        {coreItems.items.map((item, index) => {
          // const isMythicItem = mythicItems && mythicItems.includes(item);
          // const itemClassNames = classnames({"is-mythic": isMythicItem});
          return (
            <React.Fragment key={index}>
              <MythicItem {...itemProps} itemId={item}>
                {(isMythic) => {
                  const itemClassNames = classnames({ "is-mythic": isMythic });
                  return (
                    <div className={itemClassNames}>
                      <Item {...itemProps} itemId={item} spriteSize={isMythic ? mythicSpriteSize : spriteSize} webp={true} />
                    </div>
                  );
                }}
              </MythicItem>
              {/* <div className={itemClassNames}>
                <Item {...itemProps} itemId={item} />
              </div> */}
              {index !== coreItems.items.length - 1 && <ArrowRight className="arrow-right" />}
            </React.Fragment>
          );
        })}
      </div>
      <div className="item-stats">
        <div className="winrate">{`${getPercetange(coreItems.wins, coreItems.matches, 2)}% WR`}</div>
        <div className="matches">{`${numberWithCommas(coreItems.matches)} ${t("Matches")}`}</div>
      </div>
    </React.Fragment>
  );
};

const ItemDupe = (props) => {
  const { itemId, count, ...otherProps } = props;

  return (
    <div className="item-dupe">
      <Item {...otherProps} itemId={itemId} webp={true} />
      {count > 1 && <div className="count-label">{count}</div>}
    </div>
  );
};

const ItemOptions = ({ items, patch }) => {
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  return items.map((item, index) => {
    return (
      <div key={index} className="item-option">
        <Item
          className="item-img"
          itemId={item.item_id}
          spriteSize={36}
          isSprite
          patch={patch}
          ssr
          language={language}
          webp={true}
        />
        <div className="item-stats">
          <div className="winrate">{`${item.win_rate}% WR`}</div>
          <div className="matches">{`${numberWithCommas(item.matches)} ${t("Matches")}`}</div>
        </div>
      </div>
    );
  });
};

const DesktopSmall = (props) => {
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  const { className, startingItems, coreItems, items1, items2, items3, patch, mythicItems } = props;

  const itemProps = {
    className: "item-img",
    spriteSize: 36,
    mythicSpriteSize: 48,
    isSprite: true,
    patch,
    ssr: true,
    language,
    webp: true,
  };

  return (
    <div className={`content-section content-section_no-padding recommended-build_items ${className}`}>
      <div className="content-section_content starting-items">
        <div className="content-section_header">{t("Starting & Core Items")}</div>
        <div>
          <div className="item-row">
            {startingItems.items.map((item, index) => (
              <ItemDupe {...itemProps} key={index} count={item.count} itemId={item.itemId} />
            ))}
          </div>
          <div className="item-stats">
            <div className="winrate">{`${getPercetange(startingItems.wins, startingItems.matches, 2)}% WR`}</div>
            <div className="matches">{`${numberWithCommas(startingItems.matches)} ${t("Matches")}`}</div>
          </div>
        </div>
        {coreItems && coreItems.matches > 0 && (
          <div style={{ marginTop: 12 }} className="core-items">
            <CoreItemsPath coreItems={coreItems} mythicItems={mythicItems} itemProps={itemProps} />
          </div>
        )}
      </div>
      <div className="content-section_content item-options item-options-1">
        <div className="content-section_header">{t("Fourth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items1} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-2">
        <div className="content-section_header">{t("Fifth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items2} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-3">
        <div className="content-section_header">{t("Sixth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items3} patch={patch} />
        </div>
      </div>
    </div>
  );
};

const Desktop = (props) => {
  const { t } = useTranslation();
  const [language] = useGlobal("language");
  const { className, startingItems, coreItems, items1, items2, items3, patch, mythicItems } = props;

  const itemProps = {
    className: "item-img",
    spriteSize: 36,
    mythicSpriteSize: 48,
    isSprite: true,
    patch,
    ssr: true,
    language,
    webp: true,
  };

  return (
    <div className={`content-section content-section_no-padding recommended-build_items ${className}`}>
      <div className="content-section_content starting-items">
        <div className="content-section_header">{t("Starting Items")}</div>
        <div>
          <div className="item-row">
            {startingItems.items.map((item, index) => (
              <ItemDupe {...itemProps} key={index} count={item.count} itemId={item.itemId} />
            ))}
          </div>
          <div className="item-stats">
            <div className="winrate">{`${getPercetange(startingItems.wins, startingItems.matches, 2)}% WR`}</div>
            <div className="matches">{`${numberWithCommas(startingItems.matches)} ${t("Matches")}`}</div>
          </div>
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb">{t("Best for most matchups")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content core-items mythic-border-container">
        <div>
          <div className="content-section_header">{t("Core Items")}</div>
          <div>
            {coreItems && coreItems.matches > 0 && (
              <CoreItemsPath
                coreItems={coreItems}
                // mythicItems={mythicItems}
                itemProps={itemProps}
              />
            )}
          </div>
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb">{t("Build this every game")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content item-options item-options-1">
        <div className="content-section_header">{t("Fourth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items1} patch={patch} />
        </div>
        <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
          <span className="content-section_blurb">{t("Options after core build")}</span>
        </MediaQuery>
      </div>
      <div className="content-section_content item-options item-options-2">
        <div className="content-section_header">{t("Fifth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items2} patch={patch} />
        </div>
      </div>
      <div className="content-section_content item-options item-options-3">
        <div className="content-section_header">{t("Sixth Item Options")}</div>
        <div className="item-option-list">
          <ItemOptions items={items3} patch={patch} />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  // const { useRiotMythicItems } = getRiotAssetsContext();
  const { patch, startingItems } = props;
  // const {data: mythicItems} = useRiotMythicItems({patch, ssr: true});

  const dupeStartingItems = stackDuplicateItems(startingItems.items);
  const commonProps = {
    ...props,
    // mythicItems,
    startingItems: { ...startingItems, items: dupeStartingItems },
  };

  return (
    <React.Fragment>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <Desktop {...commonProps} />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_SMALL">
        <DesktopSmall {...commonProps} />
      </MediaQuery>
      <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
        <Desktop {...commonProps} />
      </MediaQuery>
    </React.Fragment>
  );
};

export default Items;
