export function getWinRateColorClassName(num: number) {
  if (num >= 70) {
    return "volxd-tier";
  } else if (num >= 60) {
    return "great-tier";
  } else if (num >= 40) {
    return "okay-tier";
  } else {
    return "shinggo-tier";
  }
}

export function getWinRateBestChampsColorClassName(num: number) {
  if (num >= 70) {
    return "volxd-tier";
  } else if (num >= 60) {
    return "great-tier";
  } else {
    return "okay-tier";
  }
}

export function getWinRateColor(num: number) {
  if (num >= 70) {
    return "#FF9B00";
  } else if (num >= 60) {
    return "#3273FA";
  } else if (num >= 40) {
    return "#FFFFFF";
  } else {
    return "#FF4E50";
  }
}

// Arena *********************************************************************************************
export function getArenaSynergyWinRateColor(num: number) {
  if (num < 45) {
    return "#FF4E50";
  } else if (num < 55) {
    return "#FCB1B2";
  } else if (num < 60) {
    return "#7EA4F4";
  } else if (num < 70) {
    return "#3273FA";
  } else {
    return "#FF9B00";
  }
}

export function getArenaPrismaticWinRateColor(num: number) {
  if (num < 45) {
    return "#FF4E50";
  } else if (num < 52.5) {
    return "#FCB1B2";
  } else if (num < 55) {
    return "#7EA4F4";
  } else if (num < 60) {
    return "#3273FA";
  } else {
    return "#FF9B00";
  }
}
// **********************************************************************************************

//  70 orange, 60 dark blue, 55 light blue, 45 light red, 40 dark red for synergies
// prismatics do 60 orange, 55 dark blue, 52.5 light, 45 light red, 40 dark red
